import { AppBar, Button, Dialog, Toolbar, Typography, IconButton, Select, MenuItem, DialogTitle, DialogContent, DialogActions, TextField, DialogContentText, FormControlLabel, FormGroup, Checkbox, FormControl, InputLabel, Theme, useTheme } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DataGrid, GridColDef, GridOverlay, GridToolbarContainer } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchApi } from '../core/fetchApi';

type Props = {
  OrderID: number | undefined;
  handleClose: () => void;
}

type OrderNotification = {
  CreatedByUserName: string;
  CustomerEmail: string;
  EmailOrderNotificationID: number;

  // For DataGrid
  id: number;
}

type AddOrderNotification = {
  CustomerEmail: string;
  SendArrivedConsigneeNotification: boolean;
  SendArrivedShipperNotification: boolean;
  SendLoadedNotification: boolean;
  SendEmptiedNotification: boolean;
  SendArrivedBorderNotification: boolean;
  SendDepartedBorderNotification: boolean;
  SendPositionNotificationMinuteInterval: number;
}

const useStyles = (theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const checkCallOptions = [
  { text: 'None', value: 0 },
  { text: '15 Mins', value: 15 },
  { text: '30 Mins', value: 30 },
  { text: '1 Hour', value: 60 },
  { text: '2 Hours', value: 120 },
  { text: '4 Hours', value: 240 }
]

export const Notifications = (props: Props) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [orderNotifications, setOrderNotifications] = useState<OrderNotification[]>([]);
  const [newNotification, setNewNotification] = useState<AddOrderNotification>({
    CustomerEmail: '',
    SendArrivedShipperNotification: true,
    SendArrivedConsigneeNotification: true,
    SendLoadedNotification: true,
    SendEmptiedNotification: true,
    SendArrivedBorderNotification: false,
    SendDepartedBorderNotification: false,
    SendPositionNotificationMinuteInterval: 0,
  });
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'CustomerEmail',
      headerName: 'Email',
      editable: true,
      flex: 1
    },
    {
      field: 'SendPositionNotificationMinuteInterval',
      headerName: 'Check Calls',
      editable: true,
      renderCell: (params) => <>{checkCallOptions.find(x => x.value === params.value)?.text}</>,
      renderEditCell: (params) => <Select
          fullWidth
          value={params.value}
          onChange={(event) => {
            const { id, api, field } = params;
            api.setEditCellValue({ id, field, value: Number(event.target.value) });
            api.stopCellEditMode({ id, field });
            event.stopPropagation();
          }}
        >
          {checkCallOptions.map((x, index) => <MenuItem key={index} value={x.value}>{x.text}</MenuItem>)}
        </Select>,
      type: 'number',
      flex: 1,
    },
    {
      field: 'SendArrivedShipperNotification',
      headerName: 'Arrived Shipper',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'SendLoadedNotification',
      headerName: 'Loaded',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'SendArrivedConsigneeNotification',
      headerName: 'Arrived Consignee',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'SendEmptiedNotification',
      headerName: 'Emptied',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'SendArrivedBorderNotification',
      headerName: 'Arrived Border',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'SendDepartedBorderNotification',
      headerName: 'Departed Border',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
    {
      field: "EmailOrderNotificationID",
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      width: 45,
      renderHeader: () => <React.Fragment />,
      renderCell: (params) => <>
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
          window.confirm("Are you sure you want to delete?") && _deleteNotification(params.id as number);
        }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </>,
    }
  ];

  const _refresh = useCallback(() => {
    setLoading(true);
    fetchApi<{
      OrderNotifications: OrderNotification[]
    }>({
      url: 'GetEmailOrderNotifications',
      payload: { OrderID: props.OrderID },
      method: 'post'
    }).then((response) => {
      setLoading(false);
      setOrderNotifications(response.OrderNotifications.map((x) => { return { ...x, id: x.EmailOrderNotificationID } }));
    });
  }, [props.OrderID]);

  const _addNotifiction = (payload: AddOrderNotification) => {

    if (!payload.CustomerEmail) {
      alert('Please enter a valid email address!');
      return;
    }

    fetchApi({
      url: `AddEmailOrderNotifications/${props.OrderID}`,
      payload,
      method: 'POST'
    })
      .then(() => {
        setOpenAdd(false);
        _refresh();
      })
      .catch(() => {
        alert('Unable to add!');
      });
  }

  const _deleteNotification = (id: number) => {
    fetchApi({
      url: `DeleteEmailOrderNotifications/${props.OrderID}`,
      payload: { EmailOrderNotificationID: id },
      method: 'POST'
    })
      .then(() => {
        _refresh();
      })
      .catch(() => {
        alert('Unable to delete!');
      });
  }

  const _updateNotification = async (payload: OrderNotification) => {
    try {
      return await fetchApi({
        url: `UpdateEmailOrderNotifications/${props.OrderID}`,
        payload,
        method: 'POST'
      });
    } catch {
      alert('Unable to update!');
    }
  }

  useEffect(() => {
    if (props.OrderID) {
      _refresh();
    } else {
      setOrderNotifications([]);
    }
  }, [props.OrderID, _refresh]);

  return <>
    <Dialog
      fullScreen
      open={!!props.OrderID}
      onClose={props.handleClose}
      disableEscapeKeyDown
      TransitionComponent={Transition}>
      <AppBar position='relative'>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={styles.title}>
            Setup Email Notifications
          </Typography>
          <Button autoFocus color="inherit" onClick={props.handleClose}>
            DONE
          </Button>
        </Toolbar>
      </AppBar>
      <DataGrid
        hideFooter
        loading={loading}
        rows={orderNotifications}
        columns={columns}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        processRowUpdate={(newRow: OrderNotification, oldRow: OrderNotification) => {
          return _updateNotification(newRow)
            .then(() => {
              _refresh();
              return newRow;
            })
            .catch(() => {
              return oldRow;
            });
        }}
        onProcessRowUpdateError={() => {
          alert('Unable to update record!');
          _refresh();
        }}
        slots={{
          toolbar: () => <GridToolbarContainer>
            <Button color="primary" size="small" onClick={_refresh}>
              <RefreshIcon fontSize="small" />
              &nbsp;Refresh
            </Button>
            <Button color="primary" size="small" onClick={() => setOpenAdd(true)}>
              <AddIcon fontSize="small" />
              &nbsp;Add
            </Button>
          </GridToolbarContainer>,
          noRowsOverlay: () => <GridOverlay>
              <Typography>No Records Found.</Typography>
          </GridOverlay>
        }}
      />
    </Dialog>
    <Dialog
      open={openAdd}
      fullWidth
      disableEscapeKeyDown
      onClose={() => setOpenAdd(false)}
      aria-labelledby="add-dialog-title">
      <DialogTitle id="add-dialog-title">Add Notification</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Send update emails for this shipment.
        </DialogContentText>
        <FormGroup>
          <TextField
            autoFocus
            fullWidth
            type="email"
            required
            value={newNotification.CustomerEmail}
            onChange={(e) => setNewNotification(prev => ({ ...prev, CustomerEmail: e.target.value }))}
            variant="outlined"
            margin="normal"
            label="Email Address"
          />
          <FormControl>
            <InputLabel id="add-check-call-label">Check Call Interval</InputLabel>
            <Select
              fullWidth
              labelId="add-check-call-label"
              label="Check Call Interval"
              value={newNotification.SendPositionNotificationMinuteInterval}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendPositionNotificationMinuteInterval: Number(e.target.value) }))}
            >
              {checkCallOptions.map((x, index) => <MenuItem key={index} value={x.value}>{x.text}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendArrivedShipperNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendArrivedShipperNotification: e.target.checked }))}
            />}
            label="Arrived Shipper"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendLoadedNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendLoadedNotification: e.target.checked }))}
            />}
            label="Loaded"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendArrivedConsigneeNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendArrivedConsigneeNotification: e.target.checked }))}
            />}
            label="Arrived Consignee"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendEmptiedNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendEmptiedNotification: e.target.checked }))}
            />}
            label="Emptied"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendArrivedBorderNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendArrivedBorderNotification: e.target.checked }))}
            />}
            label="Arrived Border"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={newNotification.SendDepartedBorderNotification}
              onChange={(e) => setNewNotification(prev => ({ ...prev, SendDepartedBorderNotification: e.target.checked }))}
            />}
            label="Departed Border"
            labelPlacement="end"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenAdd(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => _addNotifiction(newNotification)} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}