import { Backdrop, CircularProgress, Grid, Theme, useTheme } from "@mui/material";
import { useAuthDispatch } from "../contexts/auth/context";
import { useEffect } from "react";
import { useParams } from "react-router";

type RouteComponentParams = {
  email?: string;
  refreshToken?: string;
};

const useStyles = (theme: Theme) => ({
  root: {
    height: '100vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const Impersonate = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { impersonate } = useAuthDispatch();
  const { email: emailParam, refreshToken: tokenRefreshParam } = useParams<RouteComponentParams>();

  // Impersonate
  useEffect(() => {
    if (emailParam && tokenRefreshParam) impersonate(emailParam, tokenRefreshParam);
  }, [emailParam, tokenRefreshParam, impersonate]);

  return <Grid container component="main" sx={styles.root}>
    <Backdrop style={styles.backdrop} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  </Grid>
}

export default Impersonate;