import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import AuthProvider from './contexts/auth/provider';
import App from './App';

Sentry.init({
  dsn: "https://a5b5400848b2f71268f1fb7f73bb3d82@o261143.ingest.us.sentry.io/4507255208148992",
  integrations: [],
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      showDialog
      dialogOptions={{
        onClose: () => location.reload()
      }}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
