import React, { useMemo, useCallback, useState, PropsWithChildren } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import noop from "lodash.noop";
import { AuthStateProvider, AuthDispatchProvider } from "./context";
import { baseEndpoint, mediaHeaders } from "../../core/fetchApi";
import { ErrorMessages, AuthDispatchContextData, AuthStateContextData } from "./types";

const AuthContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessages>('');
  const [token, setToken] = useState("");
  const [waitingVerification, setWaitingVerification, deleteWaitingVerification] = useLocalStorage("WAITING_VERIFICATION", false,);
  const [refreshToken, setRefreshToken, deleteRefreshToken] = useLocalStorage("REFRESH_TOKEN", "",);
  const [email, setEmail, ] = useLocalStorage("EMAIL","",);
  const [initializing, setInitializing] = useState<boolean>(!!(refreshToken && !waitingVerification));

  const handleSearch = useCallback(( email: string): void => {
      setLoading(true);
      const options = {
        body: JSON.stringify({ email, DeviceName: 'MyFreight', Version: 2 }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      fetch(`${baseEndpoint}Search`, options)
      .then((response) => {
        if (response.status === 404) {
          setEmail(email);
          setErrorMessage("NOCUSTOMERS");
        }
        if (response.status !== 200) return;
        response.json().then((data: { RefreshToken: string }) => {
          setEmail(email);
          setWaitingVerification(true);
          setRefreshToken(data.RefreshToken);
        });
      })
      .catch(noop)
      .finally(() => {
        setLoading(false);
      });
    },
    [
      setLoading,
      setWaitingVerification,
      setEmail,
      setRefreshToken,
    ],
  );

  const handleSignup = useCallback((email: string, firstName: string, lastName: string, company: string, zipCode: string): void => {
      setLoading(true);
      const options = {
        body: JSON.stringify({ email, firstName, lastName, company, zipCode, DeviceName: 'MyFreight', Version: 2 }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      fetch(`${baseEndpoint}Signup`, options)
      .then((response) => {
        if (response.status !== 200) return;
        response.json().then((data: { RefreshToken: string }) => {
          setEmail(email);
          setWaitingVerification(true);
          setRefreshToken(data.RefreshToken);
        });
      })
      .catch(noop)
      .finally(() => {
        setLoading(false);
      });
    },
    [
      setLoading,
      setWaitingVerification,
      setEmail,
      setRefreshToken,
    ],
  );

  const handleRefresh = useCallback((): void => {
      setLoading(true);
      const options = {
        body: JSON.stringify({ refreshToken, DeviceName: 'MyFreight' }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      fetch(`${baseEndpoint}RefreshToken`, options)
      .then((response) => {
        if (response.status !== 200) {
          setInitializing(false);
          return;
        }
        response.json().then((data: { Token: string, ErrorMessage: ErrorMessages }) => {
          if (data.Token) {
            localStorage.setItem("TOKEN", data.Token);
            setToken(data.Token);
          }
          if (data.ErrorMessage === "NOTACTIVATED") setWaitingVerification(true);
          setErrorMessage(data.ErrorMessage);
          setInitializing(false);
        });
      })
      .catch(() => {
        setInitializing(false);
      })
      .finally(() => {
        setLoading(false);
      });
    },
    [
      refreshToken,
      setWaitingVerification,
      setErrorMessage,
      setLoading,
      setToken,
    ],
  );

  const handleVerification = useCallback((verificationCode: string): void => {
      setLoading(true);
      const options = {
        body: JSON.stringify({ verificationCode, refreshToken }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      fetch(`${baseEndpoint}EmailVerificationCode`, options)
      .then((response) => {
        // TODO: 401 Alert incorrect code?
        if (response.status !== 200) return;
        handleRefresh();
        setWaitingVerification(false);
      })
      .catch(noop)
      .finally(() => {
        setLoading(false);
      });
    },
    [
      refreshToken,
      setLoading,
      setWaitingVerification,
      handleRefresh,
    ],
  );

  const signOut = useCallback((): void => {
      const options = {
        body: JSON.stringify({ RefreshToken: refreshToken }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      fetch(`${baseEndpoint}Revoke`, options)
        .finally(() => {
          localStorage.removeItem("TOKEN");
          setToken('');
          setErrorMessage('');
          deleteRefreshToken();
          deleteWaitingVerification();
        });
      }, [
      refreshToken,
      setToken,
      setErrorMessage,
      deleteRefreshToken,
      deleteWaitingVerification,
    ]);

  const handleImpersonate = useCallback(async (emailParam: string, refreshTokenParam: string): Promise<void> => {

    async function revokePreviousRefreshToken() {
      const options = {
        body: JSON.stringify({ RefreshToken: refreshToken }),
        headers: mediaHeaders,
        method: 'post',
      } as RequestInit;
      try {
        await fetch(`${baseEndpoint}Revoke`, options);
      } finally {
        localStorage.setItem("REFRESH_TOKEN", refreshTokenParam);
      }
    }

    // Logout
    if (refreshToken && refreshToken !== refreshTokenParam) {
      await revokePreviousRefreshToken();
    }

    // Set new token
    setEmail(emailParam);
    setRefreshToken(refreshTokenParam);
    setWaitingVerification(false);

    // Reload to trigger refresh
    window.location.href = '/';
  }, [
    refreshToken,
    setEmail,
    setRefreshToken,
    setWaitingVerification,
  ]);

  const authState = useMemo<AuthStateContextData>(
    () => ({
      email,
      token,
      refreshToken,
      loading,
      initializing,
      waitingVerification,
      errorMessage,
    }),
    [
      email,
      token,
      refreshToken,
      loading,
      initializing,
      waitingVerification,
      errorMessage,
    ],
  );

  const authDispatch = useMemo<AuthDispatchContextData>(
    () => ({
      search: handleSearch,
      signup: handleSignup,
      verification: handleVerification,
      impersonate: handleImpersonate,
      refresh: handleRefresh,
      signOut,
    }),
    [
      handleSearch,
      handleSignup,
      handleVerification,
      handleImpersonate,
      handleRefresh,
      signOut,
    ],
  );

  return (
    <AuthStateProvider value={authState}>
      <AuthDispatchProvider value={authDispatch}>
        {children}
      </AuthDispatchProvider>
    </AuthStateProvider>
  );
};

export default AuthContainer;