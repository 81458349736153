import './App.css';

import React, { useEffect } from 'react';
import { useMediaQuery, CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from './contexts/auth/context';
import Login from './screens/LoginScreen';
import Signup from './screens/SignupScreen';
import Impersonate from './screens/Impersonate';
import Orders from './screens/OrdersScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { refresh } = useAuthDispatch();
  const { initializing, token, waitingVerification, refreshToken } = useAuthState();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: prefersDarkMode ? '#90CAF9' : '#175985',
          },
          secondary: {
            main: '#C5382C'
          }
        },
      }),
    [prefersDarkMode],
  );

  // Refresh Token on App Launch / Login
  useEffect(() => {
    if (refreshToken && !waitingVerification) refresh();
  }, [refreshToken, waitingVerification, refresh]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!initializing && <BrowserRouter>
        <Routes>
          {token ? <Route path="/" element={<ProtectedRoute><Orders /></ProtectedRoute>} /> : <Route path="/" element={<Login />} />}
          <Route path="/notifications" element={<ProtectedRoute><NotificationsScreen /></ProtectedRoute>} />
          <Route path="/signup" element={token ? <Navigate to="/" /> : <Signup />} />
          <Route path="/impersonate/:email/:refreshToken" element={<Impersonate />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>}
    </ThemeProvider>
  );
}

export default App;
