import { Container, Select, MenuItem, FormControlLabel, Checkbox, FormControl, FormGroup, InputLabel, Button, Snackbar, IconButton, SnackbarCloseReason, SelectChangeEvent, TextField, Divider, Stack, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import AppBar from '../components/appbar';
import { fetchApi } from '../core/fetchApi';

const useStyles = () => ({
  root: {
    flexGrow: 1,
  },
  companyProfile: {
    marginBottom: 10
  },
});

const checkCallOptions = [
  { text: 'None', value: 0 },
  { text: '15 Mins', value: 15 },
  { text: '30 Mins', value: 30 },
  { text: '1 Hour', value: 60 },
  { text: '2 Hours', value: 120 },
  { text: '4 Hours', value: 240 }
]

export type CustomerContactNotificationRule = {
  CustomerID: number;
  CustomerContactID: number;
  Name: string;

  SendPositionNotificationMinuteInterval: number;
  Consolidated: boolean;
  SendArrivedShipperNotification: boolean;
  SendArrivedConsigneeNotification: boolean;
  SendLoadedNotification: boolean;
  SendEmptiedNotification: boolean;
  SendArrivedBorderNotification: boolean;
  SendDepartedBorderNotification: boolean;
  SendPickupWaitTimeMinutesNotification: number;
  SendDeliveryWaitTimeMinutesNotification: number;
  OnlyIfShipper: boolean;
  OnlyIfConsignee: boolean;
  OnlyIfBillTo: boolean;
  OnlyIfAuthCompany: boolean;
  OnlyIfLogistics: boolean;
  OnlyIfAuthContact: boolean;
}

const NotificationsScreen = () => {
  const styles = useStyles();
  const [snackbar, setSnackbar] = useState('');
  const [rules, setRules] = useState<CustomerContactNotificationRule[]>([]);
  const [customerId, setCustomerId] = useState(0);
  const [customerContactId, setCustomerContactId] = useState(0);
  const [interval, setInterval] = useState(0);
  const [consolidated, setConsolidated] = useState(false);
  const [arrivedShipper, setArrivedShipper] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [arrivedConsignee, setArrivedConsignee] = useState(false);
  const [emptied, setEmptied] = useState(false);
  const [arrivedBorder, setArrivedBorder] = useState(false);
  const [departedBorder, setDepartedBorder] = useState(false);
  const [pickupWaitTime, setPickupWaitTime] = useState(60);
  const [deliveryWaitTime, setDeliveryWaitTime] = useState(60);
  const [onlyIfShipper, setOnlyIfShipper] = useState(false);
  const [onlyIfConsignee, setOnlyIfConsignee] = useState(false);
  const [onlyIfBillTo, setOnlyIfBillTo] = useState(false);
  const [onlyIfAuthCompany, setOnlyIfAuthCompany] = useState(false);
  const [onlyIfLogistics, setOnlyIfLogistics] = useState(false);
  const [onlyIfAuthContact, setOnlyIfAuthContact] = useState(false);

  // componentDidMount
  useEffect(() => {
    fetchApi<{ NotificationRules: CustomerContactNotificationRule[] }>({
      url: 'GetEmailNotifications'
    }).then((response) => {
      setRules(response.NotificationRules);
      if (response.NotificationRules.length === 1) {
        setCustomerContactId(response.NotificationRules[0].CustomerID);
        setCustomerContactId(response.NotificationRules[0].CustomerContactID);
      }
    });
  }, []);

  const handleCloseSnackbar = (_: Event | React.SyntheticEvent | React.MouseEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar('');
  };

  useEffect(() => {
    if (customerContactId) {
      const rule = rules.find(x => x.CustomerContactID === customerContactId);
      if (rule) {
        setCustomerId(rule.CustomerID);
        setInterval(rule.SendPositionNotificationMinuteInterval);
        setConsolidated(rule.Consolidated);
        setArrivedShipper(rule.SendArrivedShipperNotification);
        setLoaded(rule.SendLoadedNotification);
        setArrivedConsignee(rule.SendArrivedConsigneeNotification);
        setEmptied(rule.SendEmptiedNotification);
        setArrivedBorder(rule.SendArrivedBorderNotification);
        setDepartedBorder(rule.SendDepartedBorderNotification);
        setPickupWaitTime(rule.SendPickupWaitTimeMinutesNotification);
        setDeliveryWaitTime(rule.SendDeliveryWaitTimeMinutesNotification);
        setOnlyIfShipper(rule.OnlyIfShipper);
        setOnlyIfConsignee(rule.OnlyIfConsignee);
        setOnlyIfBillTo(rule.OnlyIfBillTo);
        setOnlyIfAuthCompany(rule.OnlyIfAuthCompany);
        setOnlyIfLogistics(rule.OnlyIfLogistics);
        setOnlyIfAuthContact(rule.OnlyIfAuthContact);
      }
    }
  }, [customerContactId, rules]);

  const onChange = (e: SelectChangeEvent<number>) => {
    const rule = rules.find(x => x.CustomerContactID === e.target.value);
    if (rule) setCustomerContactId(rule.CustomerContactID);
  }

  const _save = () => {
    fetchApi<{ Success: boolean, ErrorMessage: string }>({
      url: 'SaveEmailNotifications',
      method: 'POST',
      payload: {
        CustomerContactID: customerContactId,
        CustomerID: customerId,
        SendPositionNotificationMinuteInterval: interval,
        Consolidated: consolidated && interval > 0,
        SendArrivedShipperNotification: arrivedShipper,
        SendArrivedConsigneeNotification: arrivedConsignee,
        SendLoadedNotification: loaded,
        SendEmptiedNotification: emptied,
        SendArrivedBorderNotification: arrivedBorder,
        SendDepartedBorderNotification: departedBorder,
        SendPickupWaitTimeMinutesNotification: pickupWaitTime || 0,
        SendDeliveryWaitTimeMinutesNotification: deliveryWaitTime || 0,
        OnlyIfShipper: onlyIfShipper,
        OnlyIfConsignee: onlyIfConsignee,
        OnlyIfBillTo: onlyIfBillTo,
        OnlyIfAuthCompany: onlyIfAuthCompany,
        OnlyIfLogistics: onlyIfLogistics,
        OnlyIfAuthContact: onlyIfAuthContact
      }
    }).then((response) => {
      if (!response.Success) {
        alert(response.ErrorMessage);
      } else {
        setSnackbar('Successfully Saved!');
      }
    })
    .catch(() => {
      alert('Unknown error saving!');
    });
  }

  return <div style={styles.root}>
    <AppBar title="Email Notifications" />
    <Container maxWidth="md">
      <h2>Setup Email Notifications for All Shipments</h2>
      {rules.length > 1 && <FormControl fullWidth variant="outlined" style={styles.companyProfile}>
        <InputLabel id="customer-profile-label">Customer Profile</InputLabel>
        <Select
          labelId="customer-profile-label"
          label="Customer Profile"

          value={customerContactId}
          onChange={onChange}>
          {rules.map(x => {
            return <MenuItem value={x.CustomerContactID}>{x.Name}</MenuItem>
          })}
        </Select>
      </FormControl>}
      {rules.filter(x => x.CustomerContactID === customerContactId).map((x) => {
        return <React.Fragment key={x.CustomerContactID}>
          <FormControl fullWidth>
            <InputLabel id={`check-calls-label-${x.CustomerContactID}`}>Check Call Interval</InputLabel>
            <Select
              labelId={`check-calls-label-${x.CustomerContactID}`}
              label="Check Call Interval"
              value={interval}
              onChange={(e) => setInterval(e.target.value as number)}
            >
              {checkCallOptions.map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox
              checked={consolidated && interval !== 0}
              onChange={(e) => setConsolidated(e.target.checked)}
              name="consolidated"
              disabled={interval === 0}
            />}
            label="Consolidate All Position Update Emails by Check Call Interval"
          />
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={arrivedShipper}
                    onChange={(e) => setArrivedShipper(e.target.checked)}
                    name="arrivedShipper"
                  />}
                  label="Arrived Shipper"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={loaded}
                    onChange={(e) => setLoaded(e.target.checked)}
                    name="loaded"
                  />}
                  label="Loaded"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={arrivedConsignee}
                    onChange={(e) => setArrivedConsignee(e.target.checked)}
                    name="arrivedConsignee"
                  />}
                  label="Arrived Consignee"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={emptied}
                    onChange={(e) => setEmptied(e.target.checked)}
                    name="emptied"
                  />}
                  label="Emptied"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={arrivedBorder}
                    onChange={(e) => setArrivedBorder(e.target.checked)}
                    name="arrivedBorder"
                  />}
                  label="Arrived Border"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={departedBorder}
                    onChange={(e) => setDepartedBorder(e.target.checked)}
                    name="departedBorder"
                  />}
                  label="Departed Border"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider />
          <Stack direction="row" spacing={2} marginY={2}>
            <FormControl fullWidth>
              <TextField
                id={`pickup-wait-time-label-${x.CustomerContactID}`}
                type="number"
                value={pickupWaitTime || ''}
                onChange={(e) => setPickupWaitTime(parseInt(e.target.value))}
                placeholder="0 for no notification"
                label="Pickup Wait Time Exceeds (In Minutes)"
                name="pickupWaitTime"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id={`delivery-wait-time-label-${x.CustomerContactID}`}
                type="number"
                value={deliveryWaitTime || ''}
                onChange={(e) => setDeliveryWaitTime(parseInt(e.target.value))}
                placeholder="0 for no notification"
                label="Delivery Wait Time Exceeds (In Minutes)"
                name="deliveryWaitTime"
              />
            </FormControl>
          </Stack>
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfAuthCompany}
                    onChange={(e) => setOnlyIfAuthCompany(e.target.checked)}
                    name="onlyIfAuthCompany"
                  />}
                  label="If Authorization Company"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfAuthContact}
                    onChange={(e) => setOnlyIfAuthContact(e.target.checked)}
                    name="onlyIfAuthContact"
                  />}
                  label="Only If You Are Authorization Contact"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfBillTo}
                    onChange={(e) => setOnlyIfBillTo(e.target.checked)}
                    name="onlyIfBillTo"
                  />}
                  label="If Bill To Company"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfShipper}
                    onChange={(e) => setOnlyIfShipper(e.target.checked)}
                    name="onlyIfShipper"
                  />}
                  label="If Shipper Company"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfConsignee}
                    onChange={(e) => setOnlyIfConsignee(e.target.checked)}
                    name="onlyIfConsignee"
                  />}
                  label="If Consignee Company"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={onlyIfLogistics}
                    onChange={(e) => setOnlyIfLogistics(e.target.checked)}
                    name="onlyIfLogistics"
                  />}
                  label="If Logistics Company"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider />
          <br />
          <Button variant="contained" color="primary" onClick={_save}>Save</Button>
        </React.Fragment>
      })}
    </Container>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  </div>;
}

export default NotificationsScreen;