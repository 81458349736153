import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography, TextField, Link, Switch, SnackbarCloseReason } from "@mui/material";
import FormControllLabel from '@mui/material/FormControlLabel';
import { DataGrid, GridOverlay, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarColumnsButton, GridCellParams } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import RefreshIcon from '@mui/icons-material/Refresh';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import AppBar from '../components/appbar';
import React, { useCallback, useEffect, useState } from "react";
import { fetchApi, fetchFileApi } from "../core/fetchApi";
import { parseJSON } from 'date-fns';
import { format } from "date-fns-tz";
import { formatNumber } from "../core/formatNumber";
import useInterval from "../core/use-interval";
import { ILink } from "../core/link";
import { Notifications } from "../components/notifications";
import useLocalStorage from "@rehooks/local-storage";
import fileDownload from "js-file-download";
import arrayUnion from 'array-union';
import { useDebounce } from "usehooks-ts";

const useStyles = () => ({
  grid: {
    minWidth: 1000
  },
  menu: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Trip = {
  OrderID: number;
  OrderNumber: number;
  TripID: number;
  TripNumber: number;
  TrailerNumber: string;
  ProofOfDeliverySignedBy: string;
  ReferenceNumber1: string;
  OrderStopStatus: number;
  OrderStopEvent: number;
  AuthorizationCustomerNumber: string;
  VehicleSpeed: number;
  PickupDateTime: Date;
  PickupLocation: string;
  DeliveryDateTime: Date;
  DeliveryLocation: string;
  Verb: string;
  PositionLocation: string;
  PositionRepeatCount: number;
  ScheduledDateTime: Date;
  EarliestStopDateTime: Date;
  LatestStopDateTime: Date;
  PositionDateTime?: Date;
  ETADateTime: Date;
  ETAMiles: number;
  MinutesLate: number;
  LoadedMiles: number;
  StopCount: number;
  StopSequence: number;
  RequiredSpeed: number;
  IsRunningLate: boolean;
  IsRunningLatePickup: boolean;
  IsRunningLateDelivery: boolean;
  IsScheduledSoon: boolean;
  TimeCriticalMinutes: number;
  IsCritical: boolean;
  NeedsCheckCall: boolean;
  WaitingAtArrivedMinutes: number;
  TotalCharges: number | null;
  BilledVehicleTypeName: string;

  // For Completed
  HasInvoice: boolean;

  Links: ILink[];
  Hash: string;
}

const GetOrderStopStatusName = (status: number, event: number) => {
  switch (status) {
    case 95:
    case 100:
      return 'Scheduled';
    case 110:
      return `Enroute to ${(event === 1) ? 'PU' : 'DEL'}`;
    case 115:
      return `Arrived at ${(event === 1) ? 'PU' : 'DEL'}`;
    case 120:
      return 'Completed';
    default:
      return 'Unknown Status';
  }
}

const hideForActive = ['ProofOfDeliverySignedBy', 'TotalCharges'];
const hideForCompleted = ['StopCount', 'OrderStopCustomerCustomerName', 'ETADateTime', 'PositionLocation', 'PositionDateTime'];
const alwaysShow = ['ReferenceNumber1', 'PickupLocation', 'DeliveryLocation', 'StopCount', 'OrderStopCustomerCustomerName', 'ETADateTime', 'PositionLocation', 'LoadedMiles', 'Links', 'TotalCharges'];

const OrdersScreen = () => {
  const styles = useStyles();
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const debouncedOrderNumber = useDebounce(orderNumber, 500)
  const [snackbar, setSnackbar] = useState('');
  const [openShare, setOpenShare] = useState('');
  const [openNotifications, setOpenNotifications] = useState<number>();
  const [orders, setOrders] = useState<Trip[]>([]);
  const [visibleColumns, setVisibleColumns] =  useLocalStorage("ORDERS_VISIBLE_COLUMNS", alwaysShow);

  const GridCellPickupLocation = React.memo((params: GridCellParams) => {
    return <Typography><>{params.value}<br />{format(parseJSON(params.row.PickupDateTime as string), "LL/dd/yyyy kk:mm zzz")}</></Typography>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const GridCellDeliveryLocation = React.memo((params: GridCellParams) => {
    return <Typography><>{params.value}<br />{format(parseJSON(params.row.DeliveryDateTime as string), "LL/dd/yyyy kk:mm zzz")}</></Typography>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const GridCellPositionLocation = React.memo((params: GridCellParams) => {
    if (!params.row.PositionDateTime) return null;
    return <Typography><>{params.value}<br />{format(parseJSON(params.row.PositionDateTime as string), "LL/dd/yyyy kk:mm zzz")}</></Typography>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const GridCellStatus = React.memo((params: GridCellParams) => {
    if (!params.row.StopSequence) return null;
    return <Typography>
      {params.row.StopSequence} of {params.row.StopCount}
      <br />
      {GetOrderStopStatusName(params.row.OrderStopStatus, params.row.OrderStopEvent)}
    </Typography>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const GridCellNextStop = React.memo((params: GridCellParams) => {
    if (!params.row.ScheduledDateTime) return null;
    return <Typography>
      {params.row.OrderStopCustomerCustomerName}<br />
      {format(parseJSON(params.row.ScheduledDateTime as string), "LL/dd/yyyy kk:mm zzz")}
    </Typography>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const GridCellStopETA = React.memo((params: GridCellParams) => {
    if (!params.row.ETADateTime) return null;
    return params.value ? <Typography>
      <span style={(params.row.IsRunningLate) ? { color: '#C5382C', fontWeight: 500 } : undefined}>{format(parseJSON(params.value as string), "LL/dd/yyyy kk:mm zzz")}</span><br />
      {formatNumber(params.row.ETAMiles)} mi.
    </Typography> : <React.Fragment/>;
  }, (prev, next) => prev.row.Hash === next.row.Hash);

  const columns: GridColDef[] = [
    {
      field: 'ReferenceNumber1',
      headerName: 'Reference',
      renderCell: (params) => <Typography><>
        <Link href={params.row.Links.find((x: ILink) => x.Name === 'Share').Link} target="_blank" rel="noopener">{params.row.OrderNumber}</Link>
        <br />
        {params.value}
        </></Typography>,
      flex: 1
    },
    {
      field: 'BilledVehicleTypeName',
      headerName: 'Equipment Type',
      flex: 1
    },
    {
      field: 'OrderNumber',
      headerName: 'Pro #',
      flex: 1
    },
    {
      field: 'PickupLocation',
      headerName: 'Pickup',
      renderCell: (params) => <GridCellPickupLocation {...params} />,
      flex: 1
    },
    {
      field: 'PickupDateTime',
      headerName: 'Pickup Scheduled',
      valueFormatter: ({ value }) => format(parseJSON(value as string), "LL/dd/yyyy kk:mm zzz"),
      type: 'dateTime',
      flex: 1
    },
    {
      field: 'DeliveryLocation',
      headerName: 'Delivery',
      renderCell: (params) => <GridCellDeliveryLocation {...params} />,
      flex: 1
    },
    {
      field: 'DeliveryDateTime',
      headerName: 'Delivery Scheduled',
      valueFormatter: ({ value }) => format(parseJSON(value as string), "LL/dd/yyyy kk:mm zzz"),
      type: 'dateTime',
      flex: 1
    },
    {
      field: 'StopCount',
      headerName: 'Status',
      renderCell: (params) => <GridCellStatus {...params} />,
      flex: 1
    },
    {
      field: 'AuthorizationCustomerNumber',
      headerName: 'Customer',
      flex: 1
    },
    {
      field: 'OrderStopCustomerCustomerName',
      headerName: 'Next Stop',
      renderCell: (params) => <GridCellNextStop {...params} />,
      flex: 1
    },
    {
      field: 'ETADateTime',
      headerName: 'Stop ETA',
      renderCell: (params) => <GridCellStopETA {...params} />,
      flex: 1
    },
    {
      field: 'PositionLocation',
      headerName: 'Position',
      renderCell: (params) => <GridCellPositionLocation {...params} />,
      flex: 1
    },
    {
      field: 'PositionDateTime',
      headerName: 'Position Date',
      valueFormatter: (params) => params.value ? format(parseJSON(params.value as string), "LL/dd/yyyy kk:mm zzz") : '',
      type: 'dateTime',
      flex: 1
    },
    {
      field: 'LoadedMiles',
      headerName: 'Distance',
      renderCell: (params) => <Typography>
        {formatNumber(params.row.LoadedMiles)} mi.<br />
        {params.row.MinutesLate > 0 && <span style={{ color: '#C5382C' }}>
          Late: {params.row.MinutesLate} min.
        </span>}
      </Typography>,
      flex: 1
    },
    {
      field: 'TrailerNumber',
      headerName: 'Trailer',
      flex: 1
    },
    {
      field: 'ProofOfDeliverySignedBy',
      headerName: 'POD',
      flex: 1
    },
    {
      field: 'TotalCharges',
      headerName: 'Charges',
      valueFormatter: ({ value }) => value ? currencyFormatter.format(value as number) : '',
      type: 'number',
      flex: 1
    },
    {
      field: 'IsCritical',
      headerName: 'Critical',
      type: 'boolean',
      flex: 1
    },
    {
      field: "Links",
      filterable: false,
      sortable: false,
      resizable: false,
      editable: false,
      hideable: false,
      disableExport: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      width: 93,
      headerName: 'Actions',
      renderCell: (params) => <>
        <IconButton title='Share Order' size="small" aria-label="close" color="inherit" onClick={() => setOpenShare(params.row.Links.find((x: ILink) => x.Name === 'Share').Link)}>
          <ShareIcon fontSize="small" />
        </IconButton>
        {!completed && <IconButton title='Setup Notifications' size="small" aria-label="close" color="inherit" onClick={() => setOpenNotifications(params.row.OrderID)}>
          <AddAlertIcon fontSize="small" />
        </IconButton>}
        {params.row.HasInvoice && <IconButton title='Download Invoice' size="small" aria-label="close" color="inherit" onClick={() => _downloadInvoice(params.row.OrderID, params.row.OrderNumber)}>
          <DescriptionIcon fontSize="small" />
        </IconButton>}
      </>,
    }
  ];

  const _createQuote = () => {
    fetchApi<{
      URL: string,
    }>({
      url: 'CreatePersonalizedURL'
    }).then((response) => {
      window.open(response.URL);
    }).catch((error: Error) => {
      if (error.message === '401') {
        setSnackbar('Not Setup - Please contact Load One to enable quote creation.');
      }
    });
  }

  const _refresh = useCallback(() => {
    setLoading(true);
    fetchApi<{ Trips: Trip[] }>({
      url: debouncedOrderNumber ? 'GridSearchOrderNumber' : completed ? 'GridSearch' : 'Grid',
      payload: { OrderNumber: debouncedOrderNumber },
      method: 'POST'
    })
      .then((data) => {
        setOrders(data.Trips);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [completed, debouncedOrderNumber]);

  const handleCloseSnackbar = (_: Event | React.SyntheticEvent | React.MouseEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar('');
  };

  const _downloadInvoice = (orderId: number, orderNumber: number) => {
    fetchFileApi({
      url: `Invoice/${orderId}/${orderNumber}`
    }).then((res) => {
      fileDownload(res, `Invoice_${orderNumber}.pdf`);
    })
  }

  // componentDidMount
  useEffect(() => {
    _refresh();
  }, [_refresh]);

  useInterval(() => {
    _refresh();
  }, 60 * 1000);

  const columnVisibilityModel = Object.fromEntries(
    columns.map(x => [x.field, !(arrayUnion(alwaysShow, visibleColumns).indexOf(x.field) === -1 || (completed && hideForCompleted.indexOf(x.field) !== -1) || (!completed && hideForActive.indexOf(x.field) !== -1))]));

  return <div>
    <AppBar />
    <DataGrid
      rowHeight={80}
      density="compact"
      style={styles.grid}
      autoHeight
      loading={loading}
      rows={orders}
      columns={columns}
      getRowId={(x) => x.OrderID}
      columnVisibilityModel={columnVisibilityModel}
      disableRowSelectionOnClick
      onColumnVisibilityModelChange={(model) => {
        setVisibleColumns(Object.keys(model).filter(x => model[x]));
      }}
      onFilterModelChange={(model) => {
        if (model.items.length === 0 || model.items.filter(x => x.field === 'OrderNumber').length === 0) {
          setOrderNumber('')
        } else {
          setOrderNumber(model.items.filter(x => x.field === 'OrderNumber')[0].value);
        }
      }}
      slots={{
        toolbar: () => <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Button color="primary" size="small" onClick={_refresh}>
            <RefreshIcon fontSize="small" />
            &nbsp;Refresh
          </Button>
          <Button color="primary" size="small" onClick={_createQuote}>
            <AddIcon fontSize="small" />
            &nbsp;Get Quote
          </Button>
          <div style={styles.menu}>
            <FormControllLabel
              control={
                <Switch
                  checked={completed}
                  color="primary"
                  onClick={() => { 
                    setCompleted(!completed);
                  }}
                />
              }
              label={completed ? 'Completed' : 'Active'}
            />
          </div>
        </GridToolbarContainer>,
        noRowsOverlay: () => <GridOverlay>
            <Typography>No Orders Found.</Typography>
        </GridOverlay>
      }}
    />
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
    <Notifications
      OrderID={openNotifications}
      handleClose={() => setOpenNotifications(undefined)}
    />
    <Dialog
      open={!!openShare}
      onClose={() => setOpenShare('')}
      aria-labelledby="tracking-dialog-title"
    >
      <DialogTitle id="tracking-dialog-title">Send Tracking Info</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Send this URL to customers to keep them updated with the latest available information for this shipment.
          </DialogContentText>
          <TextField
            fullWidth
            variant="filled"
            margin="dense"
            label="Share URL"
            value={openShare}
            inputProps={{
              onFocus: (e) => e.target.select()
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(openShare);
                    setSnackbar('Link Copied! Use Ctrl+V to paste.');
                    setOpenShare('');
                  }}
                >
                  Copy
                </Button>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenShare('')} color="primary">
            Close
          </Button>
        </DialogActions>
    </Dialog>
  </div>
}

export default OrdersScreen;
